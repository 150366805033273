import { HttpErrorResponse } from '@angular/common/http';
import { Order } from '@app/order/interfaces/order';
import { createAction, props } from '@ngrx/store';
import { CreateOrder } from '@app/order/interfaces/create-order';

export enum ActionKeys {
    Failure = '[Order Create] failure',
    Request = '[Order Create] request',
    Success = '[Order Create] success',
    Init = '[Order Create] init',
}

export interface OrderCreateRequest {
    order: CreateOrder;
}

export const failure = createAction(ActionKeys.Failure, props<{ error: HttpErrorResponse }>());
export const request = createAction(ActionKeys.Request, props<OrderCreateRequest>());
export const success = createAction(ActionKeys.Success, props<{ data: Order }>());
export const init = createAction(ActionKeys.Init);
