import { OrderScope } from '@shared/order/enums/order-scope.enum';
import { OrderState } from '@smooved/core';
import { Order } from '../interfaces/order';

export class OrderUtils {
    public static hasWarnings(order: Order): boolean {
        if (order.state === OrderState.Cancelled) return false;
        return false;
    }

    public static isOrderScopePlant(orderScope: string): boolean {
        if (!orderScope) return false;
        return orderScope.startsWith(OrderScope.Plant);
    }

    public static isOrderScopeTelenet(orderScope: string): boolean {
        return orderScope === OrderScope.Telenet;
    }
}
