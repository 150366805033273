import { Injectable } from '@angular/core';
import {
    HeaderPartnerAgentMenuConfig,
    HeaderPartnerAgentMenuItem,
} from '@app/header/interfaces/header-partner-agent-menu-config.interface';
import { OrderUtils } from '@app/order/utils/order-utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { defaultConfig, giftConfig } from './header-partner-agent-menu.constants';

@Injectable({ providedIn: 'root' })
export class HeaderPartnerAgentMenuService {
    private readonly config = new BehaviorSubject<HeaderPartnerAgentMenuConfig>(defaultConfig);

    public get headerChanged(): Observable<HeaderPartnerAgentMenuConfig> {
        return this.config.asObservable();
    }

    public setupByScope(scope): void {
        if (OrderUtils.isOrderScopePlant(scope)) {
            this.setup(giftConfig);
            return;
        }
        this.setup(defaultConfig);
    }

    public getMenuItems(): HeaderPartnerAgentMenuItem[] {
        return Object.values(this.config.value.menuItems);
    }

    private setup(config: HeaderPartnerAgentMenuConfig): void {
        if (config) {
            this.config.next(config);
        }
    }
}
