<table
    matSort
    [matSortDirection]="defaultSortDirection"
    [matSortActive]="defaultSortColumn"
    matSortDisableClear
    mat-table
    [dataSource]="dataSource.data"
    class="u-w100p"
>
    <ng-container matColumnDef="indication">
        <th mat-header-cell *matHeaderCellDef class="__cell __cell--indication"></th>
        <td mat-cell *matCellDef="let row" class="__cell __cell--indication">
            <div class="u-flex-row u-flex-align-items-center u-flex-justify-content-center u-color-error">
                <app-icon icon="error" class="u-color-danger" size="lg" *ngIf="!!row?.indication"></app-icon>
            </div>
        </td>
    </ng-container>

    <ng-container [matColumnDef]="tableConstants.columnOrderId">
        <th mat-header-cell class="u-padding-left" *matHeaderCellDef>{{ 'PARTNER.ORDER_ID' | translate }}</th>
        <td mat-cell class="u-padding-left" *matCellDef="let row">{{ row.orderId }}</td>
    </ng-container>

    <ng-container [matColumnDef]="tableConstants.columnDeliveryDate">
        <th mat-header-cell class="u-padding-left" *matHeaderCellDef>{{ 'DELIVERY_DATE' | translate }}</th>
        <td mat-cell class="u-padding-left" *matCellDef="let row">{{ row.deliveryDate | formatDate }}</td>
    </ng-container>

    <ng-container [matColumnDef]="tableConstants.columnScheduledDate">
        <th mat-header-cell class="u-padding-left" *matHeaderCellDef mat-sort-header>{{ 'SCHEDULED_DATE' | translate }}</th>
        <td mat-cell class="u-padding-left" *matCellDef="let row">{{ row.planned?.value | formatDate }}</td>
    </ng-container>

    <ng-container [matColumnDef]="tableConstants.columnDeliveryAddress">
        <th mat-header-cell class="u-padding-left" *matHeaderCellDef>
            {{ (flagDeliveryAddressAsAddress ? 'ADDRESS' : 'DELIVERY_ADDRESS') | translate }}
        </th>
        <td mat-cell class="u-padding-left" *matCellDef="let row">{{ row.deliveryAddress | formatAddress }}</td>
    </ng-container>

    <ng-container [matColumnDef]="tableConstants.columnGift">
        <th mat-header-cell class="u-padding-left" *matHeaderCellDef>
            {{ 'PARTNER.GIFT.PRODUCT' | translate }}
        </th>
        <td mat-cell class="u-padding-left" *matCellDef="let row">{{ row.gift.nameLabels | translatedLabel | async }}</td>
    </ng-container>

    <ng-container [matColumnDef]="tableConstants.columnRealEstateAgent">
        <th mat-header-cell class="u-padding-left" *matHeaderCellDef>
            {{ 'PARTNER.SENDER' | translate }}
        </th>
        <td mat-cell class="u-padding-left" *matCellDef="let row">{{ row.realEstateAgent | name }}</td>
    </ng-container>

    <ng-container [matColumnDef]="tableConstants.columnRealEstateGroup">
        <th mat-header-cell class="u-padding-left" *matHeaderCellDef>
            {{ 'PARTNER.SENDER' | translate }}
        </th>
        <td mat-cell class="u-padding-left" *matCellDef="let row">{{ row.realEstateGroup.name }}</td>
    </ng-container>

    <ng-container [matColumnDef]="tableConstants.columnCreatedOn">
        <th mat-header-cell class="u-padding-left" *matHeaderCellDef>{{ 'CREATED_ON' | translate }}</th>
        <td mat-cell class="u-padding-left" *matCellDef="let row">{{ row.formattedCreatedOn }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell class="__cell u-padding-left u-padding-right" *matHeaderCellDef>{{ 'NAME' | translate }}</th>
        <td
            mat-cell
            *matCellDef="let row"
            [title]="row.move.user.formattedName || row.move.user.email"
            class="u-padding-left u-padding-right"
        >
            <app-user-name [user]="row.move.user"></app-user-name>
        </td>
    </ng-container>

    <ng-container [matColumnDef]="tableConstants.columnEmail">
        <th mat-header-cell class="u-padding-left" *matHeaderCellDef>{{ 'EMAIL' | translate }}</th>
        <td mat-cell class="u-padding-left" *matCellDef="let row">{{ row.move.user.email }}</td>
    </ng-container>

    <ng-container [matColumnDef]="tableConstants.columnMovingDate">
        <th mat-header-cell class="u-padding-left" *matHeaderCellDef>{{ 'MOVE.USER.MOVING_DATE' | translate }}</th>
        <td mat-cell class="u-padding-left" *matCellDef="let row">{{ row.move.formattedMovingDate }}</td>
    </ng-container>

    <ng-container [matColumnDef]="tableConstants.columnIcons">
        <th mat-header-cell class="u-padding-left" *matHeaderCellDef></th>
        <td mat-cell class="u-padding-left" *matCellDef="let row">
            <app-svg-checked class="u-margin-right-half" [checked]="row.move.telecomOrderedByAdmin" [name]="svg.Internet"></app-svg-checked>

            <app-svg-checked
                [checked]="row.move.telecomInstallationDateByAdmin"
                class="u-margin-right-half"
                [name]="svg.Calendar"
            ></app-svg-checked>

            <app-svg-checked
                [ngClass]="{ 'u-hidden': !showPhone(row.move) }"
                [checked]="row.move.telecomPhoneTransferByAdmin"
                [name]="svg.Phone"
            ></app-svg-checked>
        </td>
    </ng-container>

    <!-- Header and Row Declarations -->
    <tr mat-header-row *matHeaderRowDef="columns" class="u-hide-up-to-and-including-phone-landscape"></tr>
    <tr mat-row *matRowDef="let row; columns: columns" (click)="rowClick.emit(row)"></tr>
</table>
<mat-paginator
    [length]="dataSource.count"
    [pageSize]="pageSizeOptions[0]"
    [showFirstLastButtons]="true"
    [pageSizeOptions]="pageSizeOptions"
></mat-paginator>
